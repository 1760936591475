import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import Swiper from '../Swiper';

const ItemCarousel = ({ photos, path, photosCount, scrollTo }) => {
  const { t } = useTranslation();
  const swiperRef = useRef(null);

  useEffect(() => {
    if (scrollTo === 'next') {
      swiperRef.current?.slideNext();
    } else if (scrollTo === 'prev') {
      swiperRef.current?.slidePrev();
    }
  }, []);

  return (
    <Swiper
      ref={swiperRef}
      images={photos}
      renderItem={(image, index) => {
        const hasShowMoreBtn =
          photosCount > photos.length && index === photos.length - 1;

        return (
          <a className="image-link" href={path}>
            <Image fill src={image.thumbnail} alt="" />
            {hasShowMoreBtn && (
              <div className="show-more">
                {t('cards.item_card.show_more', { count: photosCount })}
              </div>
            )}
          </a>
        );
      }}
    />
  );
};

ItemCarousel.propTypes = {
  photos: PropTypes.array,
  photosCount: PropTypes.number,
  path: PropTypes.string,
  scrollTo: PropTypes.string
};

export default ItemCarousel;
